import * as React from 'react'

import Layout from '../components/layout'
import Seo from '../components/seo'
import Menu from '../components/Menu/Menu'

const MenuPage = () => (
  <Layout>
    <Seo
      title='Menu'
      description="we take pride in our diverse and unique menu. Whether you're in the mood for a specialty coffee, a refreshing tea, or a fun and flavourful bubble tea, we've got you covered."
    />
    <Menu />
  </Layout>
)

export default MenuPage
