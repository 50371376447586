import React from 'react'
import menu from '../../assets/images/menu.pdf'

const Menu = () => {
  return (
    <>
      <div className='container text-center px-7'>
        <h1 className='text-black pt-5 font-secondary'>MENU</h1>
        <embed src={menu} type='application/pdf' width='100%' height='600px' />
        <a href={menu} download='Brochure' className='mt-lg-3 d-block'>
          View or download our menu
        </a>
      </div>
    </>
  )
}

export default Menu
